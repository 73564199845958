import * as React from "react";
import { Link } from "gatsby";

import PageLayout from "../components/PageLayout";

// markup
const NotFoundPage = () => {
  return (
    <PageLayout>
      <h1>Page not found</h1>
      <p>
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn’t find what you were looking for.
        <br />
        <Link to="/">Go home</Link>.
      </p>
    </PageLayout>
  );
};

export default NotFoundPage;
